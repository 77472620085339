// ids for putin special category in WP
exports.categoryIds = [261, 265, 269]

exports.categoryNames = ["putin-de", "putin-en", "putin-ru-ru"]

exports.metaInfos = [
  {
    langId: "de",
    title: "20 Jahre Putin",
    subtitle: "Ein dekoder-Special",
    description: "Ein dekoder-Special",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2020/03/putin_social_de.png",
  },
  {
    langId: "en",
    title: "Putin – 20 years",
    subtitle: "A dekoder-Special",
    description: "A dekoder-Special",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2020/03/putin_social_en.png",
  },
  {
    langId: "ru",
    title: "20 лет Путина",
    subtitle: "Спецпроект dekoder.org",
    description: "Спецпроект dekoder.org",
    defaultSocialImg: "https://specials.dekoder.org/content/uploads/2020/03/putin_social_ru.png",
  }
]

exports.siteMetadata = {
  title: `Putin Special`,
  description: `Ein Multimedia-Dossier`,
  siteUrl: `https://putin.dekoder.org`,
  author: `dekoder.org`
}

exports.placeholder = {
  de: "<p>Dieses Material ist noch nicht veröffentlicht.</p>",
  en: "<p>This text is not published yet.</p>",
  ru: "<p>Русская версия будет опубликована позже.</p>",
  uk: "<p>This text is not published yet.</p>"
}

exports.pathPrefix = ""
exports.faviconPath = `../shared/src/images/favicon.png`
exports.layoutComponentPath = require.resolve("./src/layout")
exports.graphqlApi = `https://wp.dekoder.org/wp/graphql`
exports.restApi = `https://wp.dekoder.org/wp-json/wp/v2/posts`

exports.trackingId = "UA-66707787-4" // `UA-66707787-2`

exports.customHook = (createPage, siteComponent, langIds) => {
  langIds.forEach(langId => {
    const path = `/embed/${langId}`
    console.log("TOOL_EMBED", path)
    createPage({
      path,
      component: require.resolve(
        `../shared/plugins/gatsby-source-dek-wp/components/term-freq-tool/term-freq-tool-standalone.js`
      ),
      context: { langId: langId, layout: "no-layout" }
    })
  })
}
