import dekoderLogo from "../shared/src/images/logos/dekoder-white.svg"
import fsoLogoMenu from "../shared/src/images/logos/fso-menu.png"
import dekoderLogoBlack from "../shared/src/images/logos/dekoder-black.svg"
import volkswagenstiftungLogo from "../shared/src/images/logos/volkswagenstiftung.svg"
import russlandAnalysenLogo from "../shared/src/images/logos/russland-analysen.png"
import radLogo from "../shared/src/images/logos/rad.png"
import meduzaLogo from "../shared/src/images/logos/meduza.svg"
import nzzLogo from "../shared/src/images/logos/nzz.svg"

const PUTIN_STR = {
  de: "Putin",
  en: "Putin",
  ru: "Путин",
}

const MEDVEDEV_STR = {
  de: "Medwedew",
  en: "Medvedev",
  ru: "Медведев",
}

export const themeColor = "#c93324"

export const exampleTerms = {
  de: "трамп:Trump,вакцина:Impfstoff,сирия:Syrien,крым:Krim,футбол:Fußball,африка:Afrika,грузия:Georgien,эрдоган&меркель,искусственный интеллект:künstliche Intelligenz,чечня:Tschetschenien,допинг,бог:Gott,евразийский экономический союз:Eurasische Wirtschaftsunion,нормандский формат:Normandie-Format,вторая мировая война:Zweiter Weltkrieg&великая отечественная война:Großer Vaterländischer Krieg,сталинград:Stalingrad,импортозамещение:Importsubstitution,беслан,модернизация:Modernisierung&терроризм:Terrorismus,сириус&сколково",
  ru: "трамп,вакцина,сирия,крым,футбол,африка+африканский,грузия,эрдоган&меркель,искусственный интеллект,чечня,допинг,бог,евразийский экономический союз,вторая мировая война&великая отечественная война,сталинград,косово,нормандский формат,импортозамещение,беслан,сириус&сколково,модернизация&терроризм",
  en: "trump,vaccine,syria,crimea,football,africa+african,georgia,erdogan&merkel,artificial intelligence,chechnya,doping,god,eurasian economic union,second world war+world war ii&great patriotic war,stalingrad,normandy format,substitution,beslan,sirius&skolkovo,modernisation&terrorism"
}

export {
  siteMetadata,
  metaInfos,
  restApi,
  categoryIds,
  categoryNames,
} from "./meta-data-node"

export const suggestionApi =
  "https://putin.api.dekoder.org/suggestion"
export const termApi = "https://putin.api.dekoder.org/term"
export const yearRange = [2000, 2021]
export const shareProjectName = "putinspecial"
export function defaultMarkers(lang) {
  return [
    {
      value: 2000,
      legend: PUTIN_STR[lang.id],
    },
    {
      value: 2008,
      legend: MEDVEDEV_STR[lang.id],
    },
    {
      value: 2012,
      legend: PUTIN_STR[lang.id],
    },
  ]
}

export const siteLogos = [
  {
    src: dekoderLogo,
    url: {
      de: "https://specials.dekoder.org/de",
      en: "https://specials.dekoder.org/en",
      ru: "https://specials.dekoder.org/ru",
    },
    scale: 0.8,
    slogan: {
      de: "[Specials]",
      en: "[Specials]",
      ru: "[спецпроекты]",
    },
  },
]

export const projectPartners = [
  {
    name: {
      de: "dekoder | Russland entschlüsseln",
      en: "dekoder | Deciphering Russia",
      ru: "декодер | Расшифровать Россию",
    },
    text: { de: "", en: "", ru: "" },
    url: {
      de: "https://www.dekoder.org/",
      en: "https://specials.dekoder.org/en",
      ru: "https://www.dekoder.org/ru",
    },
    logo: dekoderLogoBlack,
  },
  {
    name: {
      de: "Forschungsstelle Osteuropa an der Universität Bremen",
      en: "Research Centre for East European Studies at the University of Bremen",
      ru: "Исследовательский центр Восточной Европы при Бременском университете",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.forschungsstelle.uni-bremen.de/",
    logo: fsoLogoMenu,
  },
  {
    name: {
      de: "VolkswagenStiftung",
      en: "Volkswagen Foundation",
      ru: "Volkswagen Foundation",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.volkswagenstiftung.de/",
    logo: volkswagenstiftungLogo,
  },
]

export const mediaPartners = [
  {
    name: {
      de: "Meduza",
      en: "Meduza",
      ru: "Медуза",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.meduza.io/",
    logo: meduzaLogo,
  },
  {
    name: {
      de: "Neue Zürcher Zeitung",
      en: "NZZ – Neue Zürcher Zeitung",
      ru: "NZZ – Neue Zürcher Zeitung",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.nzz.ch/meinung/russland-als-doppelstaat-wladimir-putin-haelt-die-zuegel-der-macht-fest-in-haenden-und-dennoch-gibt-es-keinen-putinismus-ld.1546289",
    logo: nzzLogo,
  },
  {
    name: {
      de: "Russland Analysen",
      en: "Russland Analysen",
      ru: "Russland Analysen",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://www.laender-analysen.de/russland/",
    logo: russlandAnalysenLogo,
  },
  {
    name: {
      de: "Russian Analytical Digest",
      en: "Russian Analytical Digest",
      ru: "Russian Analytical Digest",
    },
    text: { de: "", en: "", ru: "" },
    url: "https://css.ethz.ch/en/publications/rad.html",
    logo: radLogo,
  },
]
